import React from 'react'
import LoginBody from '../components//LoginBody'
import Footer from '../components/inc/Footer'
import Header from '../components/inc/Header'

function Login() {
  return (
    <>
        <Header/>
        <LoginBody/>
        <Footer/>
    </>
  )
}

export default Login