import React from 'react'
import longLeftArrow from "../imgs/icons/longLeftArrow.png";
import user from "../imgs/user.png";
import notification from "../imgs/icons/notification.png";
import arrowdownGrey from "../imgs/icons/arrowDownGrey.png";

export default function DashboardHeader() {
  return (
    <div class="top-dash-header d-flex align-items-center">
                <div class="dash-page-title d-flex"><img src={longLeftArrow} alt="..."/>
                    <h4>Users</h4>
                </div>
                <div class="ms-auto signout-notify d-flex">
                    <div class="btn-group notfication">
                        <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={notification} alt="..." />
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li><button class="dropdown-item" type="button">Action</button></li>
                            <li><button class="dropdown-item" type="button">Another action</button></li>
                            <li><button class="dropdown-item" type="button">Something else here</button></li>
                        </ul>
                    </div>
                    <div class="btn-group signout-user">
                        <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={user} alt="..." />
                            Abdul Hanan
                            <img class="arrow-ico-grey" src={arrowdownGrey} alt="..."/>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li><button class="dropdown-item" type="button">Action</button></li>
                            <li><button class="dropdown-item" type="button">Another action</button></li>
                            <li><button class="dropdown-item" type="button">Something else here</button></li>
                        </ul>
                    </div>
                </div>
            </div>
  )
}
