import React from 'react'
import arrowdownGrey from "../imgs/icons/arrowDownGrey.png";
import user from "../imgs/user.png";
import dot from "../imgs/icons/dot.png";

export default function DashboardOverViewContent() {
  return (
<div class="overview-content">
                        <div class="row gx-4">
                            <div class="col-lg-4">
                                <div class="overview-box">
                                    <h6 class="d-flex align-items-center">Total Customers <img src={dot} alt="..." class="ms-auto" width="24"/></h6>
                                    <p>2,430 <small class="d-flex ms-auto">20%</small></p>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="overview-box">
                                    <h6 class="d-flex align-items-center">Members <img src={dot} alt="..." class="ms-auto" width="24"/></h6>
                                    <p>1,220 <small class="d-flex ms-auto">20%</small></p>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="overview-box">
                                    <h6 class="d-flex align-items-center">Active Now <img src={dot} alt="..." class="ms-auto" width="24"/></h6>
                                    <p class="d-flex">
                                        316 
                                        <span class="users-multi-img d-flex ms-auto">
                                            <img src={user} alt="..."/>
                                            <img src={user} alt="..."/>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>        
    </div>
  )
}
