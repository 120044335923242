import React from 'react'
import {Link} from 'react-router-dom';
import house from '../imgs/icons/house.png'
import dashboard from '../imgs/icons/dashboard.png'
import project from '../imgs/icons/project.png'
import tasks from '../imgs/icons/tasks.png'
import reporting from '../imgs/icons/reporting.png'
import Vector from '../imgs/icons/Vector.png'
import fav from '../imgs/icons/fav.png'
import profile from '../imgs/icons/profile.png'
import LOGO from '../imgs/LOGO.png'
function DashboardSideNavigationBar() {
  return (
<div class="dash-side-header">
            <div class="dash-logo"><img src={LOGO} alt="..." /></div>
            <ul class="nav-side-links">
                <li><Link to="/"><img src={house} alt="..."/>Home</Link></li>
                <li><Link to="#"><img src={dashboard} alt="..."/>Dashboard</Link></li>
                <li><Link to="#"><img src={project} alt="..."/>my Projects</Link></li>
                <li><Link to="#"><img src={tasks} alt="..."/>Tasks</Link></li>
                <li><Link to="#"><img src={reporting} alt="..."/>Reporting</Link></li>
                <li><Link to="#"><img src={Vector} alt="..."/>Users</Link></li>
                <li><Link to="#"><img src={fav} alt="..."/>favourite</Link></li>
                <li><Link to="#"><img src={profile} alt="..."/>my profile</Link></li>
                <li><Link to="#"><img src={reporting} alt="..."/>Logout</Link></li>
            </ul>
        </div>
  )
}

export default DashboardSideNavigationBar