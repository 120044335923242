import React, { useState } from "react";
import Map from "./Map";
import filter from "../imgs/icons/filter.png";
import listingOne from "../imgs/listingOne.png";
import review from "../imgs/review.png";
import verify from "../imgs/verify.png";
import service from "../imgs/service.png";
import discount from "../imgs/discount.png";
import message from "../imgs/icons/message.png";
import graph from "../imgs/icons/search.png";
import arrowLeft from '../imgs/icons/arrowLeft.png';
import arrowRight from '../imgs/icons/arrowRight.png';
import {Link, useParams} from 'react-router-dom';
import { CircularProgress } from "@mui/material";

// import Paginaton from "./Paginaton";

function ListingBody(props) {
  const {id} = useParams()
  const paramsId = id ? id : '0';
  const [page,setPage] = useState("1");
  const [limit,setLimit] = useState("2");
  const [loader, setLoader] = useState(false)
  let index = 0;
  
  const nextPage = async(e) => {
    setPage(e.target.innerHTML);
    data();
  }
  
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let foo = params.get('query'); 
  const backpage = async(e) => {
    if(page !== 1){
      setPage(e.target.innerHTML-1);
      data();
    }
    else{ data()
    }
  }
  const data = async () => {
    setLoader(true)
    try {
      const res = await fetch(
        id===undefined ? `https://test-wrangler.listing.workers.dev/api/get-category-data/0?page=${page}&limit=${limit}&query=${foo}` 
        : `https://test-wrangler.listing.workers.dev/api/get-category-data/${paramsId}?page=${page}&limit=${limit}&query=${foo}`
        ,
        {
          method: "GET",
        }
        );
        const result = await res.json();
        setLoader(false)
        setCategoriesList(result?.data);
        setCount(result?.count);
        return result;
      } catch (err) {
        console.log(err.message);
      }
    };
    
    const [_categoriesList, setCategoriesList] = React.useState([]);
    const [count,setCount] = React.useState(0);
    // console.log("_categoriesList :", _categoriesList);
  
  React.useEffect(() => {
    data();
    
  }, [foo, id,page]);

  const loopPagination = () => {
    let li = []
    for(let ind=0;ind<=parseInt(count/limit);ind++)
    {
      li.push(<li><Link to={`/listing/${id}?page=${ind+1}&limit=${limit}$query=${''}`} onClick={(e)=>{ setPage(e.target.innerHTML) }}>{ind+1}</Link></li>)
    }
    return li;
  }
  return (
    <>
      <section className="listings">
        <div className="custom-listing-container">
          <div className="listing-content">
            <div className="row">
              <div className="col-md-8">
                <div className="filters-listing d-flex">
                  <p>Over 1,000 homes</p>
                  <button className="ms-auto">
                    <img src={filter} alt="..." width={14} height={14} />
                    Filters
                  </button>
                </div>
                {loader === false? _categoriesList.length > 0 &&
                    _categoriesList?.map((val) => {
                    return (
                      <div className="list-box d-flex">
                        <div className="list-box-thumb">
                          <img
                            src={listingOne}
                            alt="..."
                            width={150}
                            height={150}
                          />
                        </div>
                        <div className="list-text">
                          <div className="d-flex title-address">
                            <div className="title-review-status">
                              <h5>{val.name}</h5>
                              <div className="review-total">
                                <p>
                                  <img
                                    src={review}
                                    alt="..."
                                    width="14"
                                    height={14}
                                  />{" "}
                                  {val.rating} ·&nbsp;
                                  <span>{val.rating_count} reviews</span>
                                </p>
                                <label className="d-inline-block">
                                  {val.menu_type}
                                </label>
                              </div>
                            </div>
                            <p className="ms-auto">
                              1070 Quesada Ave Bayview-Hunters Point
                            </p>
                          </div>
                          <div className="list-services mt-2 d-flex">
                            <div className="d-flex align-items-center justify-content-center service-box">
                              <img
                                src={verify}
                                alt="..."
                                width={12}
                                height={12}
                              />
                              <p>Verified License</p>
                            </div>
                            <div className="d-flex align-items-center service-box">
                              <img
                                src={service}
                                alt="..."
                                width={14}
                                height={14}
                              />
                              <p>Emergency services</p>
                            </div>
                            <div className="d-flex align-items-center service-box">
                              <img
                                src={discount}
                                alt="..."
                                width={14}
                                height={14}
                              />
                              <p>Discounts available</p>
                            </div>
                          </div>
                          <div className="list-info d-flex">
                            <img
                              src={message}
                              alt="..."
                              width="16"
                              height="16"
                            />
                            <p>{val.description}</p>
                          </div>
                          <div className="respond-request d-flex align-items-center">
                            <div className="respond-text">
                              <p>
                                Responds in about<span>10 minutes</span>
                              </p>
                              <span>
                                <img
                                  className="me-1"
                                  src={graph}
                                  alt="..."
                                  width="16"
                                  height="16"
                                />
                                1,255 locals recently requested a quote
                              </span>
                            </div>
                            <button className="ms-auto">Request a Quote</button>
                          </div>
                        </div>
                      </div>
                    );
                  }):
                    <div className="list-box d-flex justify-content-center">
                      <CircularProgress style={{color: '#FFA800'}}/>
                    </div>}
                <div className="theme-pagination">
                    <ul>
                      
                      {
                        !loader ? loopPagination(): ''
                      }
                    </ul>
                </div>
              </div>
              {/* <Map /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
  }

export default ListingBody;