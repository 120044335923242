import React from 'react'
import { Link } from 'react-router-dom'
import DashboardHeader from './DashboardHeader'
import add from "../imgs/icons/add.png";
import DashboardOverViewContent from './DashboardOverViewContent';
import UserTable from './UserTable';

export default function DashboardBody() {
  return (
    <div class="dash-body">
        <DashboardHeader/>

    <div className="dash-user-content">
    <div class="d-flex align-items-center total-over-add">
        <h5>Total Overview</h5>
        <Link to="#" class="ms-auto"><img src={add} alt="..."/>Add User</Link>
    </div>
    </div>

    <div class="over-tbl-content">

        <DashboardOverViewContent/>
        <UserTable/>
    </div>
    </div>
  )
}
