import React from 'react'
import listingOne from "../imgs/listingOne.png";
import user from "../imgs/user.png";
import pen from "../imgs/icons/pen.png";
import trash from "../imgs/icons/trash.png";

export default function UserTable() {
  return (
    <div class="user-table-wrapper">
                        <table class="user-tbl">
                            <thead>
                                <tr>
                                    <th>Company</th>
                                    <th>Status</th>
                                    <th>About</th>
                                    <th>Users</th>
                                    <th>Quick Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex company-cell">
                                            <img src={listingOne} alt="..." width="50"/>
                                            <p>
                                                24-7 Rooter & Plumbing
                                                <span class="d-block">1421 San Pedro St, Los Angeles</span>
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="d-block customer-cell">Customer</span>
                                    </td>
                                    <td>
                                        <div class="about-cell">
                                            <p>“In an industry where the majority of companies really don't seem to care about the Customer.</p>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="users-multi-img d-flex">
                                            <img src={user} alt="..."/>
                                            <img src={user} alt="..."/>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="quick-act-ico d-flex">
                                            <img src={pen} alt="..." width="24"/>
                                            <img src={trash} alt="..." width="24" class="ms-2"/>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex company-cell">
                                            <img src={listingOne} alt="..." width="50"/>
                                            <p>
                                                24-7 Rooter & Plumbing
                                                <span class="d-block">1421 San Pedro St, Los Angeles</span>
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="d-block customer-cell">Customer</span>
                                    </td>
                                    <td>
                                        <div class="about-cell">
                                            <p>“In an industry where the majority of companies really don't seem to care about the Customer.</p>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="users-multi-img d-flex">
                                            <img src={user} alt="..."/>
                                            <img src={user} alt="..."/>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="quick-act-ico d-flex">
                                            <img src={pen} alt="..." width="24"/>
                                            <img src={trash} alt="..." width="24" class="ms-2"/>
                                        </span>
                                    </td>

                                </tr>

                                <tr>
                                    <td>
                                        <div class="d-flex company-cell">
                                            <img src={listingOne} alt="..." width="50"/>
                                            <p>
                                                24-7 Rooter & Plumbing
                                                <span class="d-block">1421 San Pedro St, Los Angeles</span>
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="d-block customer-cell">Customer</span>
                                    </td>
                                    <td>
                                        <div class="about-cell">
                                            <p>“In an industry where the majority of companies really don't seem to care about the Customer.</p>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="users-multi-img d-flex">
                                            <img src={user} alt="..."/>
                                            <img src={user} alt="..."/>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="quick-act-ico d-flex">
                                            <img src={pen} alt="..." width="24"/>
                                            <img src={trash} alt="..." width="24" class="ms-2"/>
                                        </span>
                                    </td>

                                </tr>


                                <tr>
                                    <td>
                                        <div class="d-flex company-cell">
                                            <img src={listingOne} alt="..." width="50"/>
                                            <p>
                                                24-7 Rooter & Plumbing
                                                <span class="d-block">1421 San Pedro St, Los Angeles</span>
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="d-block customer-cell">Customer</span>
                                    </td>
                                    <td>
                                        <div class="about-cell">
                                            <p>“In an industry where the majority of companies really don't seem to care about the Customer.</p>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="users-multi-img d-flex">
                                            <img src={user} alt="..."/>
                                            <img src={user} alt="..."/>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="quick-act-ico d-flex">
                                            <img src={pen} alt="..." width="24"/>
                                            <img src={trash} alt="..." width="24" class="ms-2"/>
                                        </span>
                                    </td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
  )
}
