import React from 'react'
import SignupBody from '../components/SignupBody'
import Footer from '../components/inc/Footer'
import Header from '../components/inc/Header'
function Signup() {
  return (
    <>
        <Header/>
        <SignupBody/>
        <Footer/>
    </>
  )
}

export default Signup