import React from 'react'

function AboutUsParagraph() {
  return (
<section class="about-details">
        <div class="container">
            <div class="about-text">
                <h2>About us</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec bibendum nec ante eget fermentum. Sed eu elit in arcu cursus gravida quis ut nisi. Cras auctor tempor nulla, convallis dictum mi facilisis nec. Suspendisse potenti. Quisque laoreet nisi at sapien aliquam vehicula. Duis mi nisl, ultrices nec mi eget, molestie mollis neque.</p>
            </div>
        </div>
    </section>
  )
}

export default AboutUsParagraph