import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import search from "../imgs/icons/search.png";
function SearchBar() {
  const navigate = useNavigate()
  const [keyword,setKeyword] = useState("");
  const getListing = (e) => {
    e.preventDefault();
    navigate(`/listing/?page=1&limit=5&query=${keyword}`)
  }
  return (
    <>
    <form class="d-flex search-banner justify-content-center" role="search" onSubmit={getListing} >
    <input class="form-control me-2" type="search" placeholder="Search" onChange={(e) => { setKeyword(e.target.value)}} aria-label="Search"/>
    <button type="submit"><img src={search} alt="..."/></button>
    </form>
    </>
  );
}
export default SearchBar;
