import React from 'react'

function Facts() {
  return (
    <section class="about-facts">
        <div class="container">
            <div class="about-fact-content">
                <h3>Fast facts</h3>
                <div class="row">
                    
                    <div class="col-md-3 ">
                        <div class="fact-box">
                            <h4>6m</h4>
                            <p>active listings worldwide</p>
                            <span class="d-block">as of March 31, 2022</span>
                        </div>
                    </div>

                    <div class="col-md-3 ">
                        <div class="fact-box">
                            <h4>6m</h4>
                            <p>active listings worldwide</p>
                            <span class="d-block">as of March 31, 2022</span>
                        </div>
                    </div>

                    <div class="col-md-3 ">
                        <div class="fact-box">
                            <h4>6m</h4>
                            <p>active listings worldwide</p>
                            <span class="d-block">as of March 31, 2022</span>
                        </div>
                    </div>

                    <div class="col-md-3 ">
                        <div class="fact-box">
                            <h4>6m</h4>
                            <p>active listings worldwide</p>
                            <span class="d-block">as of March 31, 2022</span>
                        </div>
                    </div>

                </div>
               
            </div>

        </div>
    </section>
  )
}

export default Facts